import * as React from "react";
import Layout from "../components/Layout";
import { HtmlHead, ResponsiveImage, Link } from "@cdv/jazz-web/components";
import { Typography, Grid, Container, Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const Head = () => (
  <HtmlHead title="Pro lektory | Metodické centrum pro dopravní psychologii">
    <meta name="foo" content="bar" />
  </HtmlHead>
);

export default function Page() {
  const aCss = {
    a: {
      textDecoration: "none",
      color: "#e05206",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
    },
    "a:hover": {
      borderBottom: "1px solid #e05206",
      transitionDuration: "0.3s",
    },
  };
  return (
    <Layout isIndex={false}>
      <Container sx={aCss}>
        <Typography variant="h1" sx={{ fontSize: "40px", marginTop: "140px", textAlign: "left" }}>
          Jste psycholog a chcete být lektorem?
        </Typography>
        <Typography variant="h3" sx={{ fontSize: { lg: "24px", xs: "18px" } }}>
          Jestli ano, vaše kvalifikační předpoklady musí odpovídat kritériím definovaným zákonem č.
          361/2000 Sb.
        </Typography>
        <Box>
          <ul>
            <li>VŠ vzdělání – titul Mgr. </li>
            <li>
              Akreditovaný psychoterapeutický výcvik nebo minimálně 5 let praxe v práci s klienty{" "}
            </li>
            <li>Absolvování kvalifikačního kurzu pořádaného Metodickým centrem</li>
          </ul>
        </Box>
        <Box>
          <Typography>
            Chcete-li se registrovat do kurzu pro lektory, <Link to="">zaregistrujte se.</Link>
            <br />
          </Typography>
        </Box>
      </Container>
    </Layout>
  );
}
